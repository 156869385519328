import cn from 'classnames';
import s from './UserIcon.module.scss';
import ImageTag from '../../../../shared/ImageTag/ImageTag';

export function getNameCaps(name: string) {
  const [forename, surname] = name.split(' ');

  return `${forename[0]}${surname[0]}`;
}

export interface UserIconProps {
  open: boolean;
  onClick: () => void;
  name: string;
  picture?: string;
}

export default function UserIcon(props: UserIconProps) {
  const { open, onClick, name, picture } = props;

  return (
    <div onClick={onClick} className={s.wrapper} role="button">
      {picture ? (
        <ImageTag
          src={picture}
          alt={name}
          lazy={false}
          imgixParams="w=80&h=80"
          className={s.picture}
        />
      ) : (
        <div className={s.name}>{getNameCaps(name)}</div>
      )}
      <span className={cn(s.arrowIcon, 'material-icons')}>
        {open ? 'expand_less' : 'expand_more'}
      </span>
    </div>
  );
}
