import React from 'react';
import cn from 'classnames';
import styles from './BurgerIcon.module.scss';
import { PropsWithTestId } from '../../../../types';

export enum BurgerIconThemes {
  dark = 'dark',
  light = 'light',
}

export interface BurgerIconProps {
  open: boolean;
  onClick: React.MouseEventHandler;
  theme: keyof typeof BurgerIconThemes;
}

export function BurgerIcon({
  open,
  onClick,
  theme,
  testId,
}: PropsWithTestId<BurgerIconProps>) {
  return (
    <div
      data-testid={testId}
      onClick={onClick}
      className={cn(styles.wrapper, styles[theme])}
    >
      <div className={open ? styles.cross : styles.burger}>
        <span />
        <span />
        <span />
      </div>
    </div>
  );
}

BurgerIcon.defaultProps = {
  theme: BurgerIconThemes.dark,
  testId: 'burger-icon',
};
