import { PropsWithChildren } from 'react';
import cn from 'classnames';
import s from './MenuItem.module.scss';
import { Anchor, AnchorProps } from '../../../../../shared/Anchor';

export interface MenuItemProps extends AnchorProps {
  icon: string;
}

export default function MenuItem(props: PropsWithChildren<MenuItemProps>) {
  const { icon, className, children, ...anchorProps } = props;

  return (
    <Anchor className={cn(s.anchor, className)} {...anchorProps}>
      <span className={cn(s.icon, 'material-icons')}>{icon}</span>
      {children}
    </Anchor>
  );
}
