import { MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { reload } from '../../../../helpers';
import Auth from '../../../../services/AuthService';
import { Profile } from '../../brandHub/pages/account/EditProfile/ProfileForm';
import { useDocumentClick } from '../../../../hooks/useDocumentClick';
import s from './UserPreferences.module.scss';
import UserIcon from './UserIcon';
import UserMenu from './UserMenu';

type User = ReturnType<typeof Auth.getShortAccountDetails>;

export default function UserPreferences() {
  const [user, setUser] = useState<User>({
    loggedIn: false,
  });
  const [profile, setProfile] = useState<Profile>();
  const [open, setOpen] = useState(false);

  const ref = useDocumentClick(open, () => {
    setOpen(false);
  });

  const handleSignOut: MouseEventHandler<HTMLAnchorElement> = async (e) => {
    e.preventDefault();
    setOpen(false);

    try {
      await Auth.logout();
      reload();
    } catch (error) {
      console.error(error);
    }
  };

  const fetchProfile = useCallback(async () => {
    try {
      const response = await Auth.fetchAccountDetails('core');
      const data = await response.json();
      setProfile(data.profile?.core || {});
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    const account = Auth.getShortAccountDetails();
    setUser(account);

    if (account.loggedIn) {
      fetchProfile();
    }
  }, [fetchProfile]);

  const handleOpenMenu = useCallback(() => {
    setOpen((state) => !state);
  }, []);

  const userName = profile
    ? `${profile.forename} ${profile.surname}`
    : `${user?.forename} ${user?.surname}`;

  if (!user.loggedIn) {
    return null;
  }

  return (
    <div ref={ref} className={s.wrapper}>
      <UserIcon
        open={open}
        onClick={handleOpenMenu}
        name={userName}
        picture={profile?.profilePhotoUrl}
      />
      {open && (
        <div className={s.menu}>
          <UserMenu
            name={userName}
            email={user?.email}
            onSignOut={handleSignOut}
          />
        </div>
      )}
    </div>
  );
}
