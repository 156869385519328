import React from 'react';
import cn from 'classnames';
import styles from './SearchButton.module.scss';
import { PropsWithTestId } from '../../../../types';

export interface SearchButtonProps
  extends React.HTMLAttributes<HTMLSpanElement> {
  open: boolean;
}

export function SearchButton({
  open,
  className,
  testId,
  ...props
}: PropsWithTestId<SearchButtonProps>) {
  const buttonClassName = cn(styles.button, 'material-icons');

  return (
    <span data-testid={testId} className={buttonClassName} {...props}>
      {open ? 'close' : 'search'}
    </span>
  );
}

SearchButton.defaultProps = {
  open: false,
  testId: 'search-button',
};
