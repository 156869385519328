import React, { useCallback, useState } from 'react';
import loadable from '@loadable/component';
import { HubHeader, HubSector, UploadedImage } from '../../../../types';
import { useHubContext } from '../context/hubContext';
import styles from './MobileNav.module.scss';
import UserPreferences from '../UserPreferences';
import ImageTag from '../../../shared/ImageTag/ImageTag';
import { BurgerIcon } from '../../../headers/ClassicHeader/BurgerIcon';
import { Anchor } from '../../../shared/Anchor';
import { SearchButton } from '../../../headers/ClassicHeader/SearchButton';
import { concatPath, allowScroll, preventScroll } from '../../../../helpers';

const Nav = loadable(
  () => import(/* webpackChunkName: "HubNav" */ '../../shared/Nav'),
);

export interface MobileNavProps {
  header: HubHeader;
  sectors: HubSector[];
  brandLogo: UploadedImage;
}

export default function MobileNav(props: MobileNavProps) {
  const {
    header: {
      headerNavBar: { disableNavbarHomeLink, displayKeywordSearch },
      navBarItems,
    },
    sectors,
    brandLogo,
  } = props;

  const { indexPath } = useHubContext();
  const [menuOpen, setMenuOpen] = useState(false);

  const searchHandler = useCallback(() => {
    document.location.assign(concatPath([indexPath, '/search/']));
  }, [indexPath]);

  const handleOpenMenu = useCallback(() => {
    if (menuOpen) {
      allowScroll();
    } else {
      preventScroll();
    }

    setMenuOpen(!menuOpen);
  }, [menuOpen]);

  return (
    <div className={styles.wrapper}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <div className={styles.body}>
              <div className={styles.bodyLeft}>
                {brandLogo && (
                  <Anchor to="/" pathPrefix={indexPath}>
                    <ImageTag
                      src={brandLogo.path}
                      alt="Brand Logo"
                      className={styles.brandLogo}
                    />
                  </Anchor>
                )}
              </div>
              <div className={styles.bodyRight}>
                <UserPreferences />
                {displayKeywordSearch && (
                  <div className={styles.mobileSearchButtonWrapper}>
                    <SearchButton open={false} onClick={searchHandler} />
                  </div>
                )}
                <BurgerIcon
                  open={menuOpen}
                  onClick={handleOpenMenu}
                  theme="light"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {menuOpen && (
        <div className={styles.menu}>
          <Nav
            links={navBarItems}
            sectors={sectors}
            displayHomeLink={!disableNavbarHomeLink}
          />
        </div>
      )}
    </div>
  );
}
