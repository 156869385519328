import { MouseEventHandler } from 'react';
import s from './UserMenu.module.scss';
import MenuItem from './MenuItem';

export interface UserMenuProps {
  name: string;
  email: string;
  onSignOut: MouseEventHandler<HTMLAnchorElement>;
}

export default function UserMenu({ name, email, onSignOut }: UserMenuProps) {
  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <div className={s.name}>{name}</div>
        <div className={s.email}>{email}</div>
      </div>
      <div className={s.body}>
        <MenuItem to="/account/profile/" icon="manage_accounts">
          My Profile
        </MenuItem>
      </div>
      <div className={s.footer}>
        <MenuItem to="/account/logout/" onClick={onSignOut} icon="logout">
          Sign Out
        </MenuItem>
      </div>
    </div>
  );
}
